module.exports = [{
      plugin: require('L:/src/corrie-brewton-com/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-123690165-2"},
    },{
      plugin: require('L:/src/corrie-brewton-com/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://stage.corriebrewton.com"},
    },{
      plugin: require('L:/src/corrie-brewton-com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
